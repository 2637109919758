// https://levelup.gitconnected.com/implementing-redux-style-state-management-with-reacts-usecontext-usereducer-hooks-c1c5596d9619

import React from 'react';
import { createContext, useReducer } from "react";
import produce from 'immer';
import getMember from '../lib/getMember';
import getTourPass from '../lib/getTourPass';

let SessionContext = createContext();  

const initialState = {
    status: 'app_loaded',
    tourPass: {
        id: null,
        token: null,
        status: 'app_loaded',
        pass: {}
    },
    member: {
        status: null,
        data: {}
    }
}

const reducer = (state, action) => {
    const newState = produce(state, draft => {
        switch (action.type) {
            case 'GET_TOUR_PASS':
                draft.tourPass.id = action.tourPassId;
                draft.tourPass.token = action.token;
                draft.tourPass.status = 'loading';
                getTourPass({ 
                    tourPassId: action.tourPassId, 
                    token: action.token,
                    dispatch: action.dispatch });
                return draft;
            case 'HANDLE_GET_TOUR_PASS':
                if (action.responseStatus === 200) {
                    draft.tourPass.status = 'loaded';
                    draft.tourPass.pass = action.responseData;    
                } else  {
                    draft.tourPass.status = 'error';
                }
                return draft;
            case 'GET_MEMBER':
                let memberId = action.memberId;
                let barcode = action.barcode;
                let token = action.token;
                if (typeof window !== 'undefined') {
                    if (typeof memberId === 'string' || typeof token === 'string') {
                        localStorage.setItem('memberId', memberId);
                        localStorage.setItem('barcode', barcode);
                        localStorage.setItem('token', token);
                    } else {
                        memberId = localStorage.getItem('memberId', memberId);
                        barcode = localStorage.getItem('barcode', barcode);
                        token = localStorage.getItem('token', token);    
                    }    
                }
                const dispatch = action.dispatch;
                getMember({ memberId, barcode, token, dispatch });
                draft.status = 'member_loading'
                return draft;
            case 'HANDLE_GET_MEMBER':
                if (action.responseStatus === 200 && action.responseData.status === 200) {
                    draft.status = 'member_loaded';
                    draft.member = action.responseData.response;
                    return draft;    
                } else {
                    draft.status = 'member_loaded_error';
                    return draft;
                }
            default:
                return draft;
        }});
    return newState
};

function SessionProvider(props) {
    let [session, dispatch] = useReducer(reducer, initialState);
    let value = { session, dispatch };
    return (
        <SessionContext.Provider value={value}>
            {props.children}
        </SessionContext.Provider>
    );
}

export { SessionContext, SessionProvider, initialState };
