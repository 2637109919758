import axios from 'axios';

const getMember = ({ memberId, barcode, token, dispatch }) => {
    if (typeof token !== 'string') {
        axios.post(`https://ten-services.herokuapp.com/api/get_member/${memberId}/${barcode}`)
        .then((r) => {
            dispatch({
                type: "HANDLE_GET_MEMBER",
                responseData: r.data,
                responseStatus: r.status
            });
        })
    } else {
        axios.post(`https://ten-services.herokuapp.com/api/get_member_by_token/${token}`)
        .then((r) => {
            dispatch({
                type: "HANDLE_GET_MEMBER",
                responseData: r.data,
                responseStatus: r.status
            });
        })        
    }
}

export default getMember;