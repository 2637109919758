import axios from 'axios';

const getTourPass = ({ tourPassId, token, dispatch }) => {
    axios.post(`https://ten-services.herokuapp.com/api/get_tour_pass/${tourPassId}/${token}`)
    .then((r) => {
        dispatch({
            type: "HANDLE_GET_TOUR_PASS",
            responseData: r.data,
            responseStatus: r.status
        });
    }).catch((r) => {
        dispatch({
            type: "HANDLE_GET_TOUR_PASS",
            responseData: r.message,
            responseStatus: 'error'
        });
    })
}

export default getTourPass;